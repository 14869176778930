import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Response } from '../../shared/services/response/response';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OrderEntryService, OrderIdResource } from '../order-entry.service';
import { OrderValidationService } from '../order-validation/order-validation.service';
import { SamplesAssociationsService } from '../../sample-association/samples-associations.service';
import { BrowserUtil, DomUtil } from '../../shared/utils';
import { FieldValidators } from '../../shared/components/field/field.validators';
import { Field } from '../../shared/components/field/field.component';
import {
  Customer,
  Discount,
  Test,
  Sample,
  SAMPLE_STATUS,
  SampleTypeStatus,
  CustomerMessage,
  AnimalTypeInputValue,
  AnimalTypeStatus,
  ElectronicOrder,
  PatientSex,
  ReferenceData,
  OrderForm,
} from '../../shared/models';
import { PetOwnerBilling, PetOwnerBillingComponent } from '../pet-owner-billing/pet-owner-billing.component';
import { CustomerSearchComponent } from '../customer-search/customer-search.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { of, Subject, Subscription, take, takeUntil } from 'rxjs';
import { ListComponent } from '../../shared/components/list/list.component';
import { EmailFaxComponent } from '../../shared/components/email-fax/email-fax.component';

import { CheckboxComponent } from '../../shared/components/checkbox/checkbox.component';
import { ElectronicOrderService } from '../electronic-order/electronic-order.service';

import { OrderValidationDetailsComponent } from '../order-validation/order-validation-details.component';
import { PatientSexFieldComponent } from '../../shared/components/sex-field/patient-sex-field.component';
import { DiscountFieldComponent } from '../../shared/components/discount-field/discount-field.component';
import { TestCodeFieldComponent } from '../../shared/components/test-code-field/test-code-field.component';
import { DateFormComponent } from '../../shared/modules/date-form/date-form.component';
import { Router } from '@angular/router';
import {
  GlobalErrorHandlerService,
  KeyboardService,
  Lab,
  LabsService,
  LoggerService,
  SnackbarComponent,
  LabNotesService,
  LabNotesComponent,
} from '@lims-common-ux/lux';
import { AppService } from '../../app.service';
import { AppStateService } from '../../app-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ElectronicOrderFieldComponent } from '../electronic-order/electronic-order-field/electronic-order-field.component';
import { CustomerCodeFieldComponent } from '../customer-code-field/customer-code-field.component';
import { SamplesComponent } from '../../shared/components/samples/samples.component';
import { PreventSaveCondition } from '../../shared/utils/prevent-save-condition';
import { ElectronicOrderComponentService } from './electronic-order-component.service';
import { OrderValidationChanges, OrderValidationComponentService } from './order-validation-component.service';
import { SampleAssociationComponentService } from './sample-association-component.service';

export type RESTRICTED_FIELDS = 'customerBarcodeId' | 'accessionNumber' | 'requisitionInfo';

@Component({
  template: '', // templates are always defined in the region component
  styleUrls: ['./operational-region.component.scss'],
  providers: [ElectronicOrderComponentService, OrderValidationComponentService, SampleAssociationComponentService],
})
export class OperationalRegionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  animalTypeSearchUrl: string;
  @Input()
  availablePatientSexes: PatientSex[] = [];
  _availableCustomerMessages: CustomerMessage[] = [];
  @Input()
  set availableCustomerMessages(value: CustomerMessage[]) {
    this._availableCustomerMessages = value;

    this.defaultAvailableCustomerMessages = [];
    this._availableCustomerMessages.forEach((customerMsg: CustomerMessage) => {
      this.defaultAvailableCustomerMessages.push(customerMsg);
    });
  }

  get availableCustomerMessages() {
    return this._availableCustomerMessages;
  }

  defaultAvailableCustomerMessages: CustomerMessage[] = [];
  _availableSamples: Sample[] = [];
  @Input()
  set availableSamples(value: Sample[]) {
    this._availableSamples = value.filter((sample) => sample.sampleTypeStatus === SampleTypeStatus.ACTIVE);

    this.defaultAvailableSamples = [];
    this._availableSamples.forEach((sample: Sample) => {
      this.defaultAvailableSamples.push(sample);
    });
  }

  get availableSamples() {
    return this._availableSamples;
  }

  defaultAvailableSamples: Sample[] = [];

  @Input()
  availableDiscounts: Discount[] = [];
  @Input()
  supportsBarcodeTranslation: boolean;
  @Input()
  editViewDefaultField: string;

  testSearchAnimalTypeCode: string;

  @ViewChild('resetButton') resetButton: ButtonComponent;
  @ViewChild('accessionNumber', { static: true }) accessionNumber: ElementRef;
  @ViewChild('patientSex', { static: true })
  patientSex: PatientSexFieldComponent;
  @ViewChild('customerCode') customerCode: CustomerCodeFieldComponent;
  // the test code component reference is used directly when processing an incoming EOrder that has tests
  @ViewChild('testCodes', { static: true }) testCodes: TestCodeFieldComponent;
  @ViewChild('collectionDate', { static: true })
  collectionDate: DateFormComponent;
  @ViewChild('discount', { static: true }) discount: DiscountFieldComponent;
  @ViewChild('emailFax', { static: true }) emailFax: EmailFaxComponent;
  @ViewChild('customerMessages', { static: true })
  customerMessages: ListComponent;
  @ViewChild('samples', { static: true }) samples: SamplesComponent;
  @ViewChild('petOwnerBilling', { static: true })
  petOwnerBilling: PetOwnerBillingComponent;
  @ViewChild('customerSearch')
  customerSearch: CustomerSearchComponent;
  @ViewChild('courierPickup')
  courierPickup: CheckboxComponent;
  @ViewChild('requisitionId') requisitionId: ElectronicOrderFieldComponent;
  @ViewChild('orderValidationDetails')
  ovDetailsComponent: OrderValidationDetailsComponent;
  @ViewChild('olderThenDaysError')
  olderThenDaysError: SnackbarComponent;
  @ViewChild('labNotes')
  labNotes: LabNotesComponent;
  // Really only used to allow for automated testing at the moment. It's present on each of the entry
  // templates for access, and if we can, should be removed when we have another way to access Accessions.
  accessionSub: Subscription;
  validateAccessionNumberResponse: Response = {};
  savedAccessionLink: string;

  orderEntryForm: FormGroup;
  maxCustomerMessages: number = 100;
  selectedCustomer: Customer;

  loading: boolean = false;
  orderSaveResponseRequested: boolean = false;
  orderSaveResponseReceived: boolean = false;
  orderSaveSuccess: boolean = false;

  lastAccessionNumber: string;
  orderResourceSub: Subscription;
  orderResource: OrderIdResource;
  orderId: string = null;
  orderValidationSub: Subscription;
  samplesAssociationsSub: Subscription;
  onDestroy$ = new Subject<void>();

  orderValidationRequested: boolean = false;
  orderValidationReceived: boolean = false;

  samplesAssociationsRequested: boolean = false;
  samplesAssociationsReceived: boolean = false;
  samplesAssociationsError: boolean = false;

  orderNotFoundMicroText: string;
  // if the electronic order is being filled out this will be true
  loadingOrderByReqId: boolean = false;
  preventSave: boolean = false; // disable save button
  referenceData: ReferenceData;

  searchingForCustomerData = false;

  snackbarChange: any;
  snackbarSub: Subscription;

  labNotesDialogOpen: boolean;

  duplicateAccessionErrorMessageOnSave: string;
  unsavedChangesMessage = this.translate.instant('ERRORS_AND_FEEDBACK.HAS_CHANGES_WARNING');

  animalTypeHasChanged = false;
  samplesHaveChanged = false;

  isExistingAccession: boolean;
  protected readonly AnimalTypeStatus = AnimalTypeStatus;

  get lab(): Lab {
    return this.labsService.currentLab;
  }

  get isEOrder(): boolean {
    return !!this?.requisitionId?.value?.requisitionId;
  }

  get ovFieldChanges(): OrderValidationChanges {
    return this.orderValidationComponentService.getOrderValidationChanges();
  }

  // Capture and suppress all keydown events when saving an order
  @HostListener('window:keydown', ['$event'])
  hostKeydown(event) {
    if (this.isFormDisabled() || (this.displayFormOverlay() && !this.shouldKeepFocus())) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  hostBeforeUnload(event) {
    if (this.orderSaveResponseRequested && !this.orderSaveResponseReceived && !this.isExistingAccession) {
      event.preventDefault();
    }
  }

  onKeydown(event: KeyboardEvent) {
    const key = BrowserUtil.isMac() && event.altKey ? this.keyboardService.unwrapEventKey(event) : event.key;
    if (key.toLowerCase() === 's' && event.altKey) {
      if (this.labNotesService.labNotesOpen) {
        this.labNotes.addLabNote();
      } else {
        this.loggerService.logAction('user-action-save-shortcut', this.orderEntryService.getLogPayload());
        this.save(event);
      }
    } else if (key.toLowerCase() === 'r' && event.altKey) {
      this.loggerService.logAction('user-action-reset-focus-shortcut', this.orderEntryService.getLogPayload());
      this.jumpReset(event);
    }
  }

  constructor(
    public readonly orderEntryService: OrderEntryService,
    public readonly electronicOrderService: ElectronicOrderService,
    public readonly orderValidationService: OrderValidationService,
    public readonly samplesAssociationsService: SamplesAssociationsService,
    private translate: TranslateService,
    private element: ElementRef,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private loggerService: LoggerService,
    private router: Router,
    public keyboardService: KeyboardService,
    protected appService: AppService,
    public appStateService: AppStateService,
    private labsService: LabsService,
    private labNotesService: LabNotesService,
    private electronicOrderComponentService: ElectronicOrderComponentService,
    private orderValidationComponentService: OrderValidationComponentService,
    private sampleAssociationComponentService: SampleAssociationComponentService,
    @Inject('Window') private window: Window
  ) {}

  ngOnInit() {
    this.electronicOrderComponentService.setComponent(this);
    this.orderValidationComponentService.setComponent(this);
    this.sampleAssociationComponentService.setComponent(this);

    this.onInputBlur = this.onInputBlur.bind(this);
    this.element.nativeElement.addEventListener('blur', this.onInputBlur, true);
    this.referenceData = this.appStateService.referenceData;

    this.orderNotFoundMicroText = this.translate.instant('ERRORS_AND_FEEDBACK.ORDER_NOT_FOUND');

    this.orderEntryForm = this.orderEntryService.createOrderEntryForm(this.appStateService.orderForm, this);

    // clear the customer if the customer code changes
    this.orderEntryForm.controls.customerCode.valueChanges.subscribe((val) => {
      if (this.customerCode && this.customerCode.value !== this.selectedCustomer?.customerCode) {
        this.selectedCustomer = null;
      }
    });

    this.orderEntryForm.controls.patientSex.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((val) => {
      if (val && !this.loadingOrderByReqId) {
        this.keyboardService.focusNext();
      }
    });

    this.snackbarSub = this.globalErrorHandlerService.errors.subscribe((error) => {
      this.snackbarChange = error;
    });

    this.appStateService.helpModalClosedEvent.subscribe(() => {
      this.accessionNumber.nativeElement.focus();
    });

    this.appStateService.snackbarErrorClosedEvent?.subscribe(() => {
      requestAnimationFrame(() => {
        if (!this.appStateService.existingAccession) {
          this.accessionNumber.nativeElement.focus();
        } else {
          this.handleEditViewFocusDefault();
        }
      });
    });

    this.appStateService.orderForm$.pipe(take(1)).subscribe((order) => {
      this.handleOrderChange(order);
    });

    if (this.appStateService.existingAccession) {
      this.setDefaults();
    }

    this.appService.showLoadingSpinner?.subscribe(() => {
      this.onShowLoadingSpinner();
    });

    this.orderEntryForm.controls.samples.valueChanges.subscribe((value) => {
      this.handleSampleChange();
    });

    this.labNotesService.labNotesOpen$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.labNotesDialogOpen = value;
    });
  }

  ngAfterViewInit(): void {
    if (this.appStateService.existingAccession) {
      this.sampleAssociationComponentService.updateExistingAccessionSampleAssociations();

      setTimeout(() => {
        const parseTests = this.orderEntryForm.get('testCodes').value;
        if (Array.isArray(parseTests)) {
          this.orderValidationService.parseRecommendations(parseTests);
        }
      }, 0);
    }
  }

  // order-entry component is destroyed on lab change
  ngOnDestroy() {
    this.element.nativeElement.removeEventListener('blur', this.onInputBlur);

    if (this.orderResourceSub) {
      this.orderResourceSub.unsubscribe();
    }

    if (this.orderValidationSub) {
      this.orderValidationSub.unsubscribe();
    }

    if (this.snackbarSub) {
      this.snackbarSub.unsubscribe();
    }

    if (this.accessionSub) {
      this.accessionSub.unsubscribe();
    }

    if (this.samplesAssociationsSub) {
      this.samplesAssociationsSub.unsubscribe();
    }

    this.electronicOrderComponentService.removeComponent();
    this.orderValidationComponentService.removeComponent();
    this.sampleAssociationComponentService.removeComponent();

    // Good practice to manage subscriptions it takeUntil, so you don't have to unsubscribe from each subscription separately
    // Won't work for observables, that should be unsubscribed before component destroy
    // url describing it:
    // https://medium.com/angular-in-depth/the-best-way-to-unsubscribe-rxjs-observable-in-the-angular-applications-d8f9aa42f6a0
    // Rwrite other subscriptions to follow same practice?
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  handleNewTestAddition(hasNewTests: boolean) {
    if (hasNewTests) {
      this.orderEntryForm.get('cancelReasonCode').disable();
    } else {
      this.orderEntryForm.get('cancelReasonCode').enable();
    }
  }

  // In edit view, focus the correct default form field as defined in operational region template.
  // If no default field is defined in the template, then focus the collection date field (Central Europe).
  handleEditViewFocusDefault() {
    requestAnimationFrame(() => {
      const fieldName = this.editViewDefaultField;

      switch (fieldName) {
        case 'customerCode':
          this.customerCode.focus();
          break;
        case 'requisitionInfo':
          this.requisitionId.focus();
          break;
        case 'collectionDate':
          this.collectionDate.focus();
          break;
      }
    });
  }

  handleShowOlderThenDaysErrorVisibilityChange(visible: boolean) {
    if (!visible) {
      this.handleEditViewFocusDefault();
    }
  }

  handleOrderChange(order?: OrderForm) {
    this.orderEntryForm.enable();

    // Reset the order entry form when there is no order to display
    if (!order) {
      requestAnimationFrame(() => {
        this.setDefaults();
        this.accessionNumber.nativeElement.focus();
      });
    }

    this.isExistingAccession = !!this.appStateService.existingAccession;

    if (this.isExistingAccession) {
      this.lastAccessionNumber = order.accessionNumber;
      this.validateAccessionNumberResponse = { data: { value: order.accessionNumber } };

      // the readonly attribute is not a boolean value in the DOM
      this.accessionNumber.nativeElement.setAttribute('readonly', true);
    }

    // Update the order customer when it exists on an Accession
    if (order?.customer) {
      this.orderCustomer({ customer: order.customer });
    } else if (order && !order.customer && this.isExistingAccession) {
      this.orderEntryForm.controls.customerCode.markAsTouched();
    }

    // Handle missing information glyph for Test Codes
    if (this.appStateService.existingAccession && order?.orderedTests.length === 0) {
      setTimeout(() => {
        this.orderEntryForm.controls.testCodes.setValue(this.orderEntryService.missingInformationGlyph);

        this.orderEntryForm.controls.testCodes.updateValueAndValidity();
      }, 0);
    }

    const allSamplesRemoved = this.appStateService.existingAccession?.samples?.every(
      (sample) => sample.status === SAMPLE_STATUS.REMOVED
    );
    if (this.appStateService.existingAccession && order && allSamplesRemoved) {
      setTimeout(() => {
        this.samples.input.nativeElement.value = this.orderEntryService.missingInformationGlyph;
        this.samples.onValueChange(this.orderEntryService.missingInformationGlyph, false);
        this.orderEntryForm.controls.samples.updateValueAndValidity();
      }, 0);
    }

    // Only update Pet Owner Billing Address if the form control exists for a given region and the
    //  information exists for the Accession owner
    if (order?.owner?.address && this.petOwnerBilling) {
      requestAnimationFrame(() => {
        const pob = new PetOwnerBilling(order.owner);
        this.petOwnerBilling.onValueChange(pob);
      });
    }
  }

  handleAnimalTypeChange(value: AnimalTypeInputValue | string) {
    if (!this.loadingOrderByReqId && !this.appStateService.existingAccession) {
      setTimeout(() => {
        this.readyOrderValidation();
        this.orderEntryForm.controls.customAnimalType.reset();
      }, 0);
    } else if (this.appStateService.existingAccession) {
      setTimeout(() => {
        if (this.orderEntryForm.controls.animalType.dirty) {
          this.animalTypeHasChanged = true;
          this.readyOrderValidation();
          this.orderEntryForm.controls.customAnimalType.reset();
        }
      }, 0);
    }
  }

  handleTestChange(testValue: Test[] | string) {
    setTimeout(() => {
      if (!this.loadingOrderByReqId && this.orderEntryForm.controls.testCodes.dirty) {
        this.readyOrderValidation();
        this.readySamplesAssociations(null);
      }
    }, 0);
  }

  handleSampleChange() {
    if (!this.loadingOrderByReqId && this.orderEntryForm.controls.samples.dirty) {
      this.readyOrderValidation();
      this.readySamplesAssociations(null);
    }
  }

  jumpReset(event: KeyboardEvent) {
    if (event) {
      event.preventDefault();
    }
    this.resetButton.focusInput();
  }

  handleCustomerSearch($event) {
    this.searchingForCustomerData = true;
  }

  onInputBlur(event) {
    // Prevent navigation while form overlay/field mask is displayed
    // the customer search check is an ugly hack. It lets the search modal focus fields when doing a e-order search
    // that returns multiple customers and the user must pick one. With out the check this overrides and attempt
    // by the modal to gain focus.
    if (
      event.target.tagName === 'INPUT' &&
      this.displayFormOverlay() &&
      !this.customerSearch.visible &&
      !this.loadingOrderByReqId
    ) {
      if (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
      event.target.focus();
    }
  }

  reqIdDirtyScanDetected(requisitionId: string) {
    this.loggerService.logAction(
      'oelog-req-id-scanned-into-dirty-form',
      this.orderEntryService.getLogPayload(requisitionId)
    );
  }

  resetSamplesAssociations() {
    this.sampleAssociationComponentService.resetSampleAssociations();
  }

  readySamplesAssociations($event) {
    this.sampleAssociationComponentService.readySamplesAssociations($event);
  }

  filterRemovedSamples(samples: Sample[]) {
    return samples.filter((sample) => {
      return !sample.isRemoved && sample.status !== SAMPLE_STATUS.REMOVED;
    });
  }

  readyOrderValidation() {
    this.orderValidationComponentService.readyOrderValidation();
  }

  readyDeclineRecommendation(item) {
    this.orderValidationComponentService.readyDeclineRecommendation(item);
  }

  onAccessionNumberInput($event) {
    if (!this.orderEntryForm.controls.accessionNumber.value) {
      this.validateAccessionNumberResponse = {};
    } else {
      this.validateAccessionNumberResponse = {
        data: { value: this.orderEntryForm.controls.accessionNumber.value },
      };
    }

    if (this.accessionSub) {
      this.accessionSub.unsubscribe();
    }

    this.accessionSub = null;

    this.duplicateAccessionErrorMessageOnSave = '';

    this.safeStringFormat($event, 'accessionNumber');
  }

  hasNonSearchableErrors(controlName: string) {
    return FieldValidators.containsErrorsThatAreNot(this.orderEntryForm.get(controlName), [
      'serviceError',
      'serviceLoading',
      'noMatches',
    ]);
  }

  checkValidity(control: AbstractControl) {
    control.updateValueAndValidity();
  }

  validateAccessionNumber(accessionNumber: string) {
    if (
      this.supportsBarcodeTranslation &&
      accessionNumber &&
      !this.hasNonSearchableErrors('accessionNumber') &&
      !this.appStateService.existingAccession &&
      accessionNumber !== this.lastAccessionNumber
    ) {
      this.accessionNumber.nativeElement.focus();
      this.accessionSub = this.orderEntryService.validateAccessionNumber(accessionNumber).subscribe((response) => {
        this.validateAccessionNumberResponse = response;
        this.accessionSub = null;

        if (response.data) {
          this.lastAccessionNumber = response.data.value;

          this.orderEntryForm.patchValue({
            accessionNumber: response.data.value,
          });

          this.keyboardService.focusNext();
        } else {
          this.accessionNumber.nativeElement.focus();
        }

        setTimeout(() => {
          this.orderEntryForm.get('accessionNumber').updateValueAndValidity();
        });
      });
    }
  }

  handleAnimalTypeResponse(animalType: AnimalTypeInputValue) {
    if (this.orderEntryForm.get('animalType') && this.orderEntryForm.get('animalType').value) {
      this.orderEntryForm.get('animalType').reset();
      this.orderEntryForm.get('customAnimalType').reset(null);
    }

    if (animalType?.animalTypeCode) {
      this.orderEntryForm.get('animalType').setValue(animalType);

      // Set testSearchAnimalTypeCode to allow test search w/animal type
      this.testSearchAnimalTypeCode = animalType.animalTypeCode;
    } else {
      // since the animal type is invalid we want to display the validation error message
      this.orderEntryForm.controls.animalType.markAsDirty();
      this.orderEntryForm.controls.animalType.updateValueAndValidity();

      // Set testSearchAnimalTypeCode to allow test search w/animal type
      this.testSearchAnimalTypeCode = null;
    }
  }

  handleCustomerSearchResponse(customers: Customer[] | null, focusNext: boolean = true) {
    if (!customers || !customers.length) {
      this.searchingForCustomerData = false;
      return;
    }

    const activeCustomers = customers.filter((customer) => !!customer.active);
    if (customers.length === 1 || activeCustomers.length === 1) {
      const customer = customers.length === 1 ? customers[0] : activeCustomers[0];
      this.orderCustomer({ customer }, false, () => {
        this.customerCode.previousSearchValue = this.customerCode.value;
        if (focusNext) {
          this._focusNextField();
        }
        this.searchingForCustomerData = false;
      });
    } else if (customers.length > 1) {
      // Open search modal with customers payload
      this.customerSearch.open(of(customers), this.isEOrder);

      setTimeout(() => {
        this.customerSearch.focusFirstResult();
        this.searchingForCustomerData = false;
      }, 0);
    }
  }

  private _focusNextField() {
    setTimeout(() => {
      if (!this.isEOrder) {
        this.requisitionId.focus();
      } else if (this.isEOrder && !this.appStateService.existingAccession) {
        this.samples.focusInput();
      }
    });
  }

  // resets the order of autocomplete items
  resetDataDefaults() {
    this._availableCustomerMessages = [];
    this.defaultAvailableCustomerMessages.forEach((item) => {
      this._availableCustomerMessages.push(item);
    });

    this._availableSamples = [];
    this.defaultAvailableSamples.forEach((item) => {
      this._availableSamples.push(item);
    });
  }

  reset(suspendReload?: boolean) {
    if (this.appStateService.existingAccession && !suspendReload) {
      this.window.location.reload();
      return;
    }

    // reset any non form state data
    this.resetDataDefaults();

    this.testCodes.resetUnresolvedEorderTestCodes();
    this.validateAccessionNumberResponse = {};
    this.accessionSub = null;
    this.selectedCustomer = null;
    this.testSearchAnimalTypeCode = null;

    // reset any form or child component data
    this.patientSex.resetInputValue();
    this.discount.resetInputValue();

    this.samples.resetInputValue();
    this.orderEntryForm.reset({
      emailFax: { emails: [], faxNumbers: [] },
      samples: {
        samples: [],
        missingSample: false,
      },
    });
    this.orderEntryForm.markAsUntouched();
    this.orderEntryForm.markAsPristine();

    this.orderEntryForm.get('collectionDate').patchValue(null);
    this.labNotesService.labNotesOpen = false;

    this.setDefaults();

    this.lastAccessionNumber = '';

    this.accessionNumber.nativeElement.focus();
  }

  clearPersistedData() {
    // clearing out fields that are subsequent to the req ID field and related data like validation id
    this.testCodes.resetUnresolvedEorderTestCodes(); // to make sure test field won't show an error
    this.testSearchAnimalTypeCode = null;

    this.patientSex.resetInputValue();
    this.discount.resetInputValue();
    this.samples.resetInputValue();
    this.customerMessages.resetList();

    Object.keys(this.orderEntryForm.controls).forEach((controlName) => {
      let initialValue;
      let excludedFields = [
        'accessionNumber',
        'customerBarcodeId',
        'customerCode',
        'requisitionInfo',
        'collectionDate',
      ];
      if (excludedFields.includes(controlName)) {
        return;
      }
      if (controlName === 'emailFax') {
        initialValue = { emails: [], faxNumbers: [] };
      } else if (controlName === 'samples') {
        initialValue = {
          samples: [],
          missingSample: false,
        };
      }

      const dirty = this.orderEntryForm.get(controlName).dirty;
      const touched = this.orderEntryForm.get(controlName).touched;
      this.orderEntryForm.get(controlName).reset(initialValue);
      if (dirty) {
        this.orderEntryForm.get(controlName).markAsDirty();
        this.orderEntryForm.get(controlName).updateValueAndValidity();
      } else if (touched) {
        this.orderEntryForm.get(controlName).markAsTouched();
        this.orderEntryForm.get(controlName).updateValueAndValidity();
      }
    });

    this.labNotesService.labNotesOpen = false;

    this.loading = false;
    this.orderValidationService.orderValidationId = null;
    this.orderValidationRequested = false;
    this.orderValidationReceived = false;
    this.animalTypeHasChanged = false;
    this.samplesHaveChanged = false;

    this.orderValidationService.reset();
    this.resetSamplesAssociations();
  }

  setDefaults() {
    this.loading = false;
    this.orderId = null;
    this.orderEntryService.electronicOrderVersion = null;
    this.orderResource = null;
    this.orderValidationService.orderValidationId = null;
    this.orderValidationRequested = false;
    this.orderValidationReceived = false;
    this.animalTypeHasChanged = false;
    this.samplesHaveChanged = false;

    this.orderValidationService.reset();

    this.resetSamplesAssociations();

    const done = () => {
      setTimeout(() => {
        this.appService.loadingSpinnerVisible = this.loading = false;
        this.orderSaveResponseReceived = false;
        this.orderSaveResponseRequested = false;
      }, 10);
    };

    if (!this.appStateService.existingAccession) {
      this.orderResourceSub = this.orderEntryService.getOrderId().subscribe({
        next: (response) => {
          // Response contains orderId and order create href
          this.orderId = response.orderId;
          this.orderResource = response;
          this.loggerService.logAction('oe-order-resource-update-added', {
            orderId: this.orderId,
            orderResourceLink: this.orderResource._links.create.href,
          });
          done();
        },
        error: () => {
          this.loggerService.logAction('oe-order-resource-update-failed', {
            orderId: this.orderId,
            orderResourceLink: this.orderResource._links.create.href,
          });

          this.router.navigateByUrl('/unavailable');
        },
      });
    } else {
      this.loggerService.logAction('oe-accession-resource-loaded', {
        orderId: this.appStateService.existingAccession,
      });
      done();
    }
  }

  orderCustomer(orderData?: { customer?: Customer }, navigateToNext: boolean = false, callback?: Function) {
    // we reset the customer request here to fix async possibility (long request through getCustomer,
    // customerSearch is opened and result picked)...long request could override search result
    let customer: Customer = null;

    if (orderData) {
      customer = orderData.customer;
    }

    if (customer && customer?.customerCode) {
      this.applyOrderCustomerWithCode(customer, navigateToNext, callback);
    } else if (!orderData || customer === null) {
      this.orderEntryForm.patchValue({ customerCode: '' });
      this.selectedCustomer = null;

      setTimeout(() => {
        if (!this.isEOrder) {
          this.customerSearch.openButton.nativeElement.focus();
        }

        if (callback) {
          callback();
        }
      }, 0);
    }
  }

  private applyOrderCustomerWithCode(customer: Customer, navigateToNext: boolean = false, callback?: Function) {
    this.selectedCustomer = customer;

    setTimeout(() => {
      this.customerSearch.previousActiveElement = null;

      this.customerCode.patch(customer.customerCode);

      if (!this.isEOrder) {
        if (!this.selectedCustomer && this.customerSearch.previousActiveElement) {
          this.customerSearch.previousActiveElement.focus();
        } else if (navigateToNext) {
          // Focus behaviors tested in acceptance tests
          this.customerSearch.openButton.nativeElement.focus();
          this.keyboardService.focusNext();
        }
      } else if (this.isEOrder && !callback) {
        this.samples.focusInput();
      }

      this.orderEntryForm.controls.customerCode.updateValueAndValidity();

      if (callback) {
        callback();
      }
    }, 0);
  }

  save(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    // Check all conditionals that should prevent order save
    if (!this.preventOrderSave(true) && this.checkSampleAssociations()) {
      this.onSubmit(this.orderEntryForm);
    }
  }

  checkSampleAssociations(): boolean {
    // OK to save
    if (
      this.samplesAssociationsService.isReadyToSave(this.orderValidationService.getBlockedContextualizedTestIds()) ||
      this.orderEntryForm.controls.cancelReasonCode.value
    ) {
      return true;
    }

    if (document.activeElement === document.body) {
      const focusElement: HTMLElement = document.querySelector('#orderValidationDetailsLink');
      if (focusElement) {
        focusElement.focus();
      }
    }

    // Pop sample association modal
    this.ovDetailsComponent.openSampleAssociations();

    this.loggerService.logAction(
      'oelog-sample-association-required',
      this.orderEntryService.getLogPayload('order-save-incomplete')
    );

    return false;
  }

  // a wrapper for the private logger service so components building from this class can access the logger
  logAction(actionName: string, payload: any): void {
    this.loggerService.logAction(actionName, payload);
  }

  onSubmit(form: FormGroup): void {
    // This condition should never happen. Failure logic should be handled in preventOrderSave method.
    if (this.preventOrderSave(true)) {
      throw new Error('Error: Save is not allowed. Please check logs.');
    }

    this.loading = true;
    this.orderSaveResponseReceived = false;
    this.orderSaveResponseRequested = true;
    this.duplicateAccessionErrorMessageOnSave = '';

    if (this.appStateService.existingAccession) {
      this.orderEntryService
        .updateAccession(
          this.appStateService.existingAccession,
          form,
          this.selectedCustomer,
          this.orderValidationService.orderValidationId,
          this.samplesAssociationsService.getSavableAssociations(form.get('testCodes').value),
          this.orderValidationService.manuallyBlockedContextualizedTestIds
        )
        .subscribe({
          next: () => {
            this.loading = false;
            this.reset();
          },
          error: (err) => {
            // flush open requests and show an error to the user
            this.orderSaveFailed();
            this.loading = false;
            this.globalErrorHandlerService.handleError(err);
          },
        });
    } else {
      this.orderEntryService
        .saveOrder(
          this.orderResource,
          form,
          this.selectedCustomer,
          this.orderValidationService.orderValidationId,
          this.samplesAssociationsService.getSavableAssociations(form.get('testCodes').value),
          this.orderValidationService.manuallyBlockedContextualizedTestIds
        )
        .subscribe({
          next: (response) => {
            this.savedAccessionLink = response['_links'].self.href;
            this.loading = false;
            this.reset();
            this.orderSavedSuccessfully();

            this.loggerService.logAction(
              'oelog-save-complete',
              this.orderEntryService.getLogPayload('order-save-complete')
            );
          },
          error: (err) => {
            // flush open requests and show an error to the user
            this.orderSaveFailed();
            this.loading = false;

            // Pop snackbar error
            this.globalErrorHandlerService.handleError(err);

            if (err.error && err.error.exceptionType === 'SampleAssociationException') {
              this.loggerService.logAction(
                'oelog-sample-association-correction-required',
                this.orderEntryService.getLogPayload(err.error.displayErrorMessage)
              );
            }

            if (err.error && err.error.exceptionType === 'DuplicateAccessionException') {
              this.duplicateAccessionErrorMessageOnSave = err.error.displayErrorMessage;

              this.orderEntryForm.controls.accessionNumber.updateValueAndValidity();

              this.displayFormOverlay();

              this.loggerService.logAction(
                'oelog-Duplicate-accession-number',
                this.orderEntryService.getLogPayload(err.error.displayErrorMessage)
              );
            }

            this.loggerService.logAction('oelog-save-failed', this.orderEntryService.getLogPayload(err));
          },
        });
    }
  }

  public onReset($event?) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      this.loggerService.logAction('user-action-reset-button', this.orderEntryService.getLogPayload());

      this.reset();
    }
  }

  orderSavedSuccessfully() {
    this.orderSaveResponseReceived = true;
    this.orderSaveSuccess = true;
  }

  orderSaveFailed() {
    this.orderSaveResponseReceived = true;
    this.orderSaveSuccess = false;
  }

  safeStringFormat($event, field?: string): void {
    DomUtil.persistCursor($event.target, async () => {
      $event.target.value = $event.target.value.toUpperCase();
      this.orderEntryForm.get(field).setValue($event.target.value);
    });
  }

  logPreventSaveError(errorDescription: string, submitLog: boolean): void {
    if (submitLog) {
      this.loggerService.logAction('oelog-order-save-attempt', this.orderEntryService.getLogPayload(errorDescription));
    }
  }

  // Returns true if not in a savable state
  preventOrderSave(submitLog?: boolean): boolean {
    // Step through conditionals and log failure/eject as quickly as possible
    // This method fires via user action AND Angular lifecycle (e.g. OnChange, etc.)
    // Incremental/fail fast will prevent UX performance issues
    const shouldPrevent = this.preventSaveConditions.find((condition) => condition.preventCheck());

    if (shouldPrevent) {
      this.logPreventSaveError(shouldPrevent.message, submitLog);
      return true;
    }
    return false;
  }

  private preventCondition(message: string, condition: () => boolean): PreventSaveCondition {
    return new PreventSaveCondition(message, condition);
  }

  private readonly preventSaveConditions: PreventSaveCondition[] = [
    this.preventCondition('hasPendingRequests', () => this.appStateService.hasPendingRequests),
    this.preventCondition('hasUnsavedLabNotesError', () => this.labNotes?.hasUnsavedLabNotes),
    this.preventCondition('isLoadingError', () => this.loading),
    this.preventCondition(
      'hasOrderResourceError',
      () => !this.appStateService.existingAccession && (!this.orderId || !this.orderResource)
    ),
    this.preventCondition(
      'hasSnackbarAuthError',
      () =>
        this.snackbarChange && this.snackbarChange instanceof HttpErrorResponse && this.snackbarChange.status === 401
    ),
    this.preventCondition('invalidFormError', () => this.orderEntryForm.invalid),
    this.preventCondition('testCodeFieldInUseError', () => this.preventSave),
    this.preventCondition('hasPetOwnerBillingError', () => this.petOwnerBilling && this.petOwnerBilling._form.invalid),
    this.preventCondition(
      'hasCustomerMessageErrors',
      () =>
        this.customerMessages &&
        (this.customerMessages.showNoMatchesFound || this.customerMessages.containsMaxTextSizeError())
    ),
    this.preventCondition('hasIncompleteInteractionErrors', () =>
      this.customerMessages?.hasIncompleteInteractionError()
    ),
    this.preventCondition(
      'hasCustomerCodeErrors',
      () =>
        !this.orderEntryForm.get('customerCode').value ||
        (this.orderEntryForm.get('customerCode').value &&
          this.orderEntryForm.get('customerCode').value['customerCode'] === '') ||
        (!this.selectedCustomer && this.orderEntryForm.get('customerCode').value)
    ),
    this.preventCondition(
      'hasOrderValidationErrors',
      () => this.orderValidationRequested && !this.orderValidationReceived
    ),
    this.preventCondition(
      'validationIdMissing',
      () =>
        !this.appStateService.existingAccession &&
        !this.orderValidationService.orderValidationId &&
        this.orderValidationRequested
    ),
    this.preventCondition(
      'hasSamplesAssociationPendingError',
      () => this.samplesAssociationsRequested && !this.samplesAssociationsReceived
    ),
    this.preventCondition('hasBarcodeTranslationError', () =>
      this.supportsBarcodeTranslation ? !this.validateAccessionNumberResponse.data : false
    ),
    this.preventCondition(
      'noChangesInOrderEntryForm',
      () => this.appStateService.existingAccession && this.orderEntryForm.pristine
    ),
  ];

  getErrorValidations(name) {
    const field = this.orderEntryForm.get(name);
    // Why is owner validation fired repeatedly on load? Because the method is called within the Template.
    // console.log('get error validations', name);
    let validations;

    if (field && field.dirty) {
      validations = Field.getErrorValidations(field.errors);
    }
    return validations || [];
  }

  displayFormOverlay() {
    const orderSavePending = this.orderSaveResponseRequested && !this.orderSaveResponseReceived;
    const invalidOrderId = !this.orderId && !this.appStateService.existingAccession;
    const fieldFocusRestricted = this.shouldKeepFocus();
    const electronicOrderLoadPending = this.getEorderLoadingStatus();

    return orderSavePending || invalidOrderId || fieldFocusRestricted || electronicOrderLoadPending;
  }

  shouldKeepFocus(
    restricted: RESTRICTED_FIELDS | RESTRICTED_FIELDS[] = ['accessionNumber', 'requisitionInfo', 'customerBarcodeId']
  ) {
    const form = this.orderEntryForm;
    let i, field;

    if (this.loadingOrderByReqId && Array.isArray(restricted)) {
      return true;
    } else if (this.loadingOrderByReqId) {
      return false;
    }

    if (!Array.isArray(restricted)) {
      restricted = [restricted];
    }

    const ln: number = restricted.length;

    for (i = 0; i < ln; i++) {
      field = form.get(restricted[i]);
      if (
        FieldValidators.containsErrorsThatAreNot(field, ['required', 'text']) ||
        (restricted[i] === 'accessionNumber' && this.accessionSub) ||
        this.duplicateAccessionErrorMessageOnSave
      ) {
        return true;
      }
    }

    return false;
  }

  getEorderLoadingStatus() {
    return this.loadingOrderByReqId;
  }

  isFormDisabled(): boolean {
    return this.orderSaveResponseRequested && !this.orderSaveResponseReceived;
  }

  displayViewDetails(): boolean {
    return (
      (!this.orderSaveResponseReceived && this.orderValidationReceived && !this.samplesAssociationsRequested) ||
      (this.orderSaveResponseReceived && !this.orderSaveSuccess && this.orderValidationReceived) ||
      !!this.appStateService.existingAccession
    );
  }

  handleReqIdSearch($event) {
    this.loadingOrderByReqId = true;
  }

  handleElectronicOrder(electronicOrder: ElectronicOrder) {
    this.electronicOrderComponentService.handleElectronicOrder(electronicOrder);
  }

  private onShowLoadingSpinner() {
    const olderThen30Days = this.appStateService.existingAccession
      ? this.appStateService.existingAccession.oldAccession
      : false;
    requestAnimationFrame(() => {
      if (!this.appStateService.existingAccession) {
        this.accessionNumber.nativeElement.focus();
      } else if (!olderThen30Days) {
        this.handleEditViewFocusDefault();
      } else {
        this.olderThenDaysError.show();
      }
    });
  }
}
